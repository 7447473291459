@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300&display=swap');

:root{
  --cadre: #121313;
}

/***********************************************************************/
/******************************  MOBILE   ******************************/
/***********************************************************************/

@media only screen and (max-width: 1199px){
  
  .profileH-Center {
    width: 100%;
    height: 200px;
  }
  
  .profileH {
    width: 100%;
    height: 200px;
    margin-top: 30px;
    vertical-align: top;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
  }

  .left {
    background-color: transparent;
    z-index: 2;
    color: black;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .right {
    background-color: transparent;
    z-index: 2;
    text-align: center;
    width: 40vw;
    margin: auto;
    margin-top: 20px;
  }

  .footer{
    color: black;
    font-size: xx-small;
  }

  .wallet{
    margin-block: 10%;
  }

  .logo{
    margin-left: 20%;
  }

  .asset{
    display:inline-block;
    position:relative;
    overflow:hidden;
    z-index: 2;
    max-width: 80%;
    max-height: auto;
    margin: 50px;
    border: 10px inset white; 
    box-shadow: 0 0 0 15px var(--cadre);
  }

  .tagcloud {
    text-align: center;
  }

}

/***********************************************************************/
/******************************  DESKTOP  ******************************/
/***********************************************************************/

@media only screen and (min-width: 1200px){

  .profileH {
    display: inline-block;
    width: 30%;
    height: 70vh;
    margin-top: 30px;
    vertical-align: top;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: black;
    background-color: transparent;
  }
  
  .profileH-Center {
    display: inline-block;
    width: 70%;
    height: 70vh;
  }

  .left {
    background-color: transparent;
    z-index: 2;
    position: fixed;
    top: 30%;
    left: 15%;
    color: white;
  }
  
  .right {
    background-color: transparent;
    z-index: 2;
    position: fixed;
    top: 30%;
    left: 75%;
    width: 15%;
  }

  .footer{
    color: black;
  }

  .asset{
    display:inline-block;
    position:relative;
    overflow:hidden;
    z-index: 2;
    max-width: 20%;
    max-height: auto;
    margin: 50px;
    border: 10px inset white; 
    box-shadow: 0 0 0 15px var(--cadre);
  }

  .tagcloud {
    text-align: center;
    width: 60%;
    margin: auto;
  }

}

/***********************************************************************/
/******************************  COMMON  *******************************/
/***********************************************************************/


* {
  font-family: 'Jura', sans-serif;
  box-sizing: border-box;
}

button {
  background-color: white;
  color: black;
}

.page{
  z-index: 1;
  height: 70vh;
  background-color: black;
  text-align: center;
  padding-top: 10vh;
}

.image {
  z-index: 2;
  width: auto;
  max-height: 80%;
  border: 10px inset white; 
  box-shadow: 0 0 0 15px var(--cadre);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.image:hover {
  transform:scale(1.1);
}

.image-concept {
  margin-block: 5vh;
  width: 70%;
  height: auto;
  filter: grayscale(100%);
  width:100%;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.image-concept:hover{
  filter: grayscale(0);
}

.market {
  text-align: center;
}



.asset img{
  width:100%;
  height:100%;
}
.asset .infos-hover{
  display:block;
  position:absolute;
  left:0;
  bottom:0;
  width:100%;
  height:0;
  color:#fff;
  text-align:center;
  background:rgba(0,0,0,0.8);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.asset .infos-hover i{
  margin-top:60px;
  font-size:50px;
}
.asset .infos-hover p{
  text-align:center;
}
.asset:hover .infos-hover{
  height:100%;
}